import axios from "axios";

export default {
  namespaced: true,
  state() {
    return {
      news: [],
      isLoading: true,
    };
  },
  mutations: {
    fetchNews(state, { posts }) {
      posts.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      posts.forEach((post) => { post.createdAt = this._vm.$getDate(post.createdAt) });
      state.news = posts;
    },
  },
  actions: {
    fetchNews(context) {
      context.state.isLoading = true;
      context.rootState.event = false;
      axios
        .get(`${this._vm.$hostname}/news`, { withCredentials: true })
        .then(({ data }) => {
          if (data.posts) context.commit("fetchNews", data);
          else if (data.error === "Too Many Request IP!") throw "You've made too many requests.";
          else throw "Something went wrong!";

          context.state.isLoading = false;
        })
        .catch((err) => {
          context.rootState.messageEvent = err;
          context.rootState.event = true;
        });
    },
  },
  getters: {
    news: (state) => state.news,
    isLoading: (state) => state.isLoading,
  },
};
