<template>
  <v-card
    min-width="300px"
    min-height="545px"
    height="75vh"
    class="d-flex align-center flex-column"
    color="#1d1f20"
  >
    <the-antet :who="'sig'"></the-antet>
    <v-form
      v-model="valid"
      style="width: 100%"
      class="d-flex align-center flex-column pt-7"
    >
      <slot-input-field
        :style="{ width: `${displaySize(65, -0.15)}%` }"
        message="We need this in case you make a payment."
      >
        <v-text-field
          class="text-body-2"
          color="white"
          v-model="fullName"
          :style="{ borderRadius: '10px' }"
          label="Full Name"
          outlined
          dense
          dark
          autofocus
          required
        ></v-text-field>
      </slot-input-field>
      <slot-input-field
        :style="{ width: `${displaySize(65, -0.15)}%` }"
        message="We need this in case you make a payment."
      >
        <v-autocomplete
          class="text-body-2 ma-0 pa-0"
          color="white"
          item-color="#1d1f20"
          v-model="country"
          :style="{ borderRadius: '10px' }"
          :items="countryList"
          label="Country"
          outlined
          dense
          dark
          required
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.split("--")[0] }}
          </template>
          <template slot="item" slot-scope="data">
            <div
              class="d-flex align-center justify-space-between"
              :style="{ width: '100%' }"
            >
              <div class="text-truncate" style="width: 180px">
                {{ data.item.split("--")[0] }}
              </div>
              <country-flag
                class="mb-1 pt-1"
                :shadow="true"
                :country="data.item.split('--')[1]"
                size="normal"
              />
            </div>
          </template> </v-autocomplete
      ></slot-input-field>
      <slot-input-field
        :style="{ width: `${displaySize(65, -0.15)}%` }"
        message="In case you win any prize, this will be displayed in the Winners Section. Also it is used to connect into your account."
      >
        <v-text-field
          class="text-body-2"
          v-model="username"
          label="Username"
          color="white"
          :rules="usernameRules"
          :style="{ borderRadius: '10px' }"
          :counter="25"
          outlined
          dense
          dark
          required
        ></v-text-field
      ></slot-input-field>
      <slot-input-field
        :style="{ width: `${displaySize(65, -0.15)}%` }"
        message="This will be the e-mail we will contact you own, if you win any of our prizes."
      >
        <v-text-field
          class="text-body-2"
          color="white"
          v-model="email"
          :rules="$store.getters['auth/emailRules']"
          :style="{ borderRadius: '10px' }"
          label="E-mail"
          outlined
          dense
          dark
          required
        ></v-text-field
      ></slot-input-field>
      <slot-input-field :style="{ width: `${displaySize(65, -0.15)}%` }">
        <v-text-field
          class="text-body-2 removeBottomField"
          v-model="password"
          label="Password"
          :type="passType"
          :append-icon="passType === 'text' ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="togglePassType"
          outlined
          dense
          dark
          :style="{ borderRadius: '10px' }"
          color="white"
          :rules="passwordRules"
          :counter="20"
          required
        ></v-text-field
      ></slot-input-field>
      <div
        class="d-flex align-center"
        :style="{ width: `${displaySize(55, -0.15)}%` }"
      >
        <v-checkbox
          id="agreeTerms"
          v-model="agreeTerms"
          :class="['pt-0', 'mt-0']"
          color="white"
          hide-details
          dark
          dense
        ></v-checkbox>
        <label
          for="agreeTerms"
          :style="{ fontSize: `${displaySize(60, 0.05)}%` }"
          class="regularText white-color ma-0 pa-0 pt-1"
        >
          I agree to the
          <a
            class="forgotPass"
            target="_blank"
            href="/about/Terms%20and%20Conditions"
            >Terms and Conditions</a
          >,<a
            class="forgotPass"
            target="_blank"
            href="/about/Privacy%20Policy"
          >
            Privacy Policy </a
          >and the
          <a
            class="forgotPass"
            target="_blank"
            href="/about/Contest%20Regulation"
            >Contest Regulation</a
          >.
        </label>
      </div>
      <div
        class="d-flex align-center"
        :style="{ width: `${displaySize(55, -0.15)}%` }"
      >
        <v-checkbox
          id="subsNews"
          v-model="subsNews"
          :class="['pt-0', 'mt-0']"
          color="white"
          hide-details
          dark
          dense
        ></v-checkbox>
        <label
          for="subsNews"
          :style="{ fontSize: `${displaySize(60, 0.05)}%` }"
          class="regularText white-color ma-0 pa-0 pt-1"
        >
          I want to receive game news on my email.
        </label>
      </div>
      <p
        :style="{ opacity: `${$store.getters['auth/error'] ? 1 : 0}` }"
        class="red--text font-weight-bold text-caption ma-0 pa-0"
      >
        {{ $store.getters["auth/messageError"] }}&nbsp;
      </p>
      <loading-circle
        :position="'relative'"
        :size="40"
        v-if="$store.getters['auth/isLoading']"
      ></loading-circle>
      <v-btn
        v-else
        :dark="
          !valid ||
          !username ||
          !password ||
          !email ||
          !fullName ||
          !country ||
          !agreeTerms
        "
        :disabled="
          !valid ||
          !username ||
          !password ||
          !email ||
          !fullName ||
          !country ||
          !agreeTerms
        "
        color="#ffffff"
        class="btnText text-capitalize rounded-lg"
        large
        @click="signUp"
        :width="`${displaySize(120, 0.05)}px`"
      >
        Sign Up
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import TheAntet from "./TheAntet.vue";
import CountryFlag from "vue-country-flag";
import SlotInputField from "./SlotInputField.vue";
import { Countries } from "../../../utils/countries";
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: { TheAntet, CountryFlag, SlotInputField, LoadingCircle },
  data: () => ({
    valid: false,
    email: "",
    username: "",
    fullName: "",
    country: "",
    agreeTerms: false,
    subsNews: true,
    countryList: Countries,
    password: "",
    passType: "password",
  }),
  computed: {
    aditionalRules() {
      return [
        (v) => v[0] !== " " || "This must not start with space",
        (v) =>
          (v[0] !== " " && v[v.length - 1] !== " ") ||
          "This must not end with space",
      ];
    },
    usernameRules() {
      return [(v) => !v.includes("@") || "This must not contain '@'."].concat(
        this.$store.getters["auth/usernameRules"].concat(this.aditionalRules)
      );
    },
    passwordRules() {
      return this.$store.getters["auth/passwordRules"].concat(
        this.aditionalRules
      );
    },
  },
  methods: {
    togglePassType() {
      this.passType = this.passType === "text" ? "password" : "text";
    },
    signUp() {
      this.$store.dispatch("auth/signUp", {
        email: this.email,
        username: this.username,
        password: this.password,
        country: this.country.split("--")[0],
        fullName: this.fullName,
        subsNews: this.subsNews,
      });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> label {
  font-size: 13px;
}
.v-text-field--outlined >>> button {
  font-size: 18px;
}
.removeBottomField >>> .v-text-field__details {
  margin-bottom: 0px;
}

.forgotPass {
  color: #00a9de;
}
.forgotPass:hover {
  color: #03bff9;
  transform: scale(1.01);
}
</style>
