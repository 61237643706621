<template>
  <v-card class="pb-3" color="#1d1f20"
    ><div
      class="d-flex align-center flex-column"
      v-if="!$store.getters['auth/props'].deleteUser"
    >
      <p class="poppinsText text-h5 mt-4">Account information</p>
      <hr class="mx-auto breakline" style="width: 80%" />
      <v-form
        v-model="valid"
        class="d-flex align-center flex-column"
        style="padding: 5% 0%; width: 100%"
      >
        <v-text-field
          id="fullName"
          :class="['text-body-2', edits.fullName ? '' : 'readOnly', 'pl-8']"
          color="white"
          :readonly="!edits.fullName"
          :rules="requiredRule"
          :append-outer-icon="edits.fullName ? closeEditIcon : editIcon"
          @click:append-outer="toogleEdit('fullName')"
          v-model="fullName"
          :style="{
            borderRadius: '10px',
            width: `${displaySize(60, -0.2)}%`,
          }"
          label="Full Name"
          outlined
          dense
          dark
          required
        ></v-text-field>
        <v-text-field
          id="username"
          :class="['text-body-2', edits.username ? '' : 'readOnly', 'pl-8']"
          v-model="username"
          :rules="usernameRules"
          :counter="25"
          :readonly="!edits.username"
          :append-outer-icon="edits.username ? closeEditIcon : editIcon"
          @click:append-outer="toogleEdit('username')"
          label="Username"
          color="white"
          :style="{
            borderRadius: '10px',
            width: `${displaySize(60, -0.2)}%`,
          }"
          outlined
          dense
          dark
          required
        ></v-text-field>
        <v-autocomplete
          id="country"
          class="text-body-2 ma-0 pa-0"
          :class="[edits.country ? '' : 'readOnly', 'pl-8']"
          color="white"
          :rules="requiredRule"
          :readonly="!edits.country"
          :append-outer-icon="edits.country ? closeEditIcon : editIcon"
          @click:append-outer="toogleEdit('country')"
          item-color="#1d1f20"
          v-model="country"
          :style="{
            borderRadius: '10px',
            width: `${displaySize(60, -0.2)}%`,
          }"
          :items="countryList"
          label="Country"
          outlined
          dense
          dark
          required
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.split("--")[0] }}
          </template>
          <template slot="item" slot-scope="data">
            <div
              class="d-flex align-center justify-space-between"
              :style="{ width: '100%' }"
            >
              <div class="text-truncate" style="width: 180px">
                {{ data.item.split("--")[0] }}
              </div>
              <country-flag
                class="mb-1 pt-1"
                :shadow="true"
                :country="data.item.split('--')[1]"
                size="normal"
              />
            </div>
          </template>
        </v-autocomplete>
        <v-text-field
          class="text-body-2 readOnly pl-8"
          color="white"
          :value="$store.getters['user'].email"
          append-outer-icon="mdi-checkbox-blank"
          :style="{
            borderRadius: '10px',
            width: `${displaySize(60, -0.2)}%`,
          }"
          label="E-mail"
          outlined
          dense
          hide-details
          readonly
          dark
          required
        ></v-text-field>
        <v-checkbox
          v-model="subsNews"
          :style="{ width: `${displaySize(60, -0.2)}%` }"
          class="my-2 mr-1 pl-8"
          label="I want to receive game news on my email."
          color="white"
          hide-details
          @click="toogleEdit('subsNews')"
          dark
          dense
        ></v-checkbox>
        <v-btn
          outlined
          color="#ffffff"
          class="btnText text-capitalize green--text rounded-lg"
          small
          style="border: 3px solid green"
          :style="{ opacity: !isEditing ? 0 : 1 }"
          :disabled="!isEditing || !valid"
          :dark="!valid"
          @click="editUser"
          width="20%"
          >Save
        </v-btn>
      </v-form>
      <v-btn
        :disabled="$store.getters['isLoading']"
        :dark="$store.getters['isLoading']"
        color="#ffffff"
        class="btnText text-capitalize rounded-lg mt-1"
        large
        @click="logOut"
        :width="`${displaySize(120, 0.05)}px`"
      >
        Logout
      </v-btn>
      <v-btn
        text
        color="error"
        class="btnText text-capitalize rounded-lg mt-3"
        @click="deleteUser"
        x-small
      >
        Delete Account
      </v-btn>
      <v-btn
        v-if="isMobile"
        class="align-self-end"
        text
        fab
        absolute
        dark
        style="top: 2%; right: 2%"
        x-small
        @click="$store.state.auth.dialog = false"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <div v-else class="py-8 px-6 d-flex align-center flex-column">
      <p
        class="
          text-center
          regularText
          text-subtitle-1
          font-weight-black
          px-6
          mb-5
        "
      >
        Are you sure you want to delete your account?
      </p>
      <p class="regularText text-center text-body-2 px-5 mb-7">
        If you've already unlocked some of our Services, you will not have
        access to them anymore.
      </p>
      <div class="d-flex justify-space-between">
        <v-btn
          :disabled="$store.getters['isLoading']"
          :dark="$store.getters['isLoading']"
          style="font-size: 80%"
          color="#d50909"
          class="btnText text-capitalize black--text mr-4"
          rounded
          @click="deleteUser"
          :width="`${displaySize(120, 0.07)}px`"
        >
          Delete
        </v-btn>
        <v-btn
          color="#ffffff"
          class="btnText text-capitalize black--text ml-4"
          style="font-size: 75%"
          rounded
          @click="$store.state.auth.dialog = false"
          :width="`${displaySize(120, 0.07)}px`"
        >
          No, keep it
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { Countries, findCountryName } from "../../../utils/countries";
import CountryFlag from "vue-country-flag";

export default {
  components: { CountryFlag },
  data: () => ({
    username: "",
    fullName: "",
    country: "",
    subsNews: false,
    edits: {
      username: false,
      fullName: false,
      country: false,
      subsNews: false,
    },
    countryList: Countries,
    valid: false,
    editIcon: "mdi-pencil",
    closeEditIcon: "mdi-close-circle",
  }),
  computed: {
    requiredRule() {
      return [(v) => !!v || "This is rquired"];
    },
    isEditing() {
      for (const key in this.edits) if (this.edits[key]) return true;
      return false;
    },
    usernameRules() {
      return [
        (v) => !v.includes("@") || "This must not contain '@'.",
        (v) => v[0] !== " " || "This must not start with space",
        (v) =>
          (v[0] !== " " && v[v.length - 1] !== " ") ||
          "This must not end with space",
      ].concat(this.$store.getters["auth/usernameRules"]);
    },
  },
  methods: {
    async editUser() {
      const payload = {};
      if (this.$store.getters["user"].username !== this.username)
        payload.username = this.username;
      if (this.$store.getters["user"].fullName !== this.fullName)
        payload.fullName = this.fullName;
      if (this.$store.getters["user"].country !== this.country.split("--")[0])
        payload.country = this.country.split("--")[0];
      if (this.$store.getters["user"].subsNews !== this.subsNews)
        payload.subsNews = this.subsNews;

      for (const edit in this.edits) this.edits[edit] = false;

      const edited = await this.$store.dispatch({ type: "editUser", payload });
      if (edited === true) {
        this.$store.getters["user"].username = this.username;
        this.$store.getters["user"].fullName = this.fullName;
        this.$store.getters["user"].country = this.country.split("--")[0];
        this.$store.getters["user"].subsNews = this.subsNews;
      } else {
        this.username = this.$store.getters["user"].username;
        this.fullName = this.$store.getters["user"].fullName;
        this.subsNews = this.$store.getters["user"].subsNews;
      }
    },
    logOut() {
      this.$store.dispatch({ type: "logOut" });
    },
    deleteUser() {
      if (!this.$store.getters["auth/props"].deleteUser) {
        this.$store.state.auth.dialog = false;
        setTimeout(() => {
          this.$store.commit("auth/changeComponent", {
            component: "TheAccount",
            props: { deleteUser: true },
          });
        }, 350);
      } else this.$store.dispatch({ type: "deleteUser" });
    },
    toogleEdit(field) {
      if (this.edits[field])
        if (field === "country")
          this[field] = findCountryName(this.$store.getters["user"].country);
        else this[field] = this.$store.getters["user"][field];
      this.edits[field] = !this.edits[field];

      if (this.edits[field]) document.getElementById(field).focus();
    },
  },
  created() {
    this.username = this.$store.getters["user"].username;
    this.fullName = this.$store.getters["user"].fullName;
    this.country = findCountryName(this.$store.getters["user"].country);
    this.subsNews = this.$store.getters["user"].subsNews;
  },
};
</script>

<style scoped>
.readOnly >>> fieldset {
  border-color: rgba(255, 255, 255, 0.24);
  border: 1px solid rgba(255, 255, 255, 0.24);
}
.v-text-field--outlined >>> button {
  font-size: 18px;
}
.v-text-field--outlined >>> .mdi-checkbox-blank {
  opacity: 0;
}

/* :key="edits.country" */
</style>
