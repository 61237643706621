import axios from "axios";
import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";

import authStore from "./apps/auth/store";
import mainStore from "./apps/main/store";
import gameStore from "./apps/game/store";
import newsStore from "./apps/news/store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main: mainStore,
    game: gameStore,
    auth: authStore,
    news: newsStore,
  },
  state() {
    return {
      isLoading: true,
      event: false,
      messageEvent: "",
      user: false,
      currentPage: "",
      feedbackOpen: false,
      route: null,
      previousRoute: null,
      startAreea: false,
      currentAreea: ""
    };
  },
  mutations: {
    currentPage(state, { page }) {
      state.currentPage = page;
    },
    fetchUser(state, user) {
      state.user = user;
    },
    SET_ROUTE(state, { to, from }) {
      state.route = to;
      state.previousRoute = from;
    },
    // SAVE_AREEA(state, { newAreea }) {
    //   const startAreea = state.startAreea;
    //   const newStartAreea = new Date().getTime();
    //   const currentAreea = state.currentAreea;

    //   if (startAreea) {
    //     const areeaCookie = this._vm.$cookies.get("areea") || '';
    //     this._vm.$cookies.set("areea", areeaCookie + `A:${currentAreea},${startAreea},${newStartAreea};`);
    //   }
    //   state.startAreea = newStartAreea
    //   state.currentAreea = newAreea
    // }
  },
  actions: {
    fetchUser(context) {
      context.state.isLoading = true;

      axios
        .get(`${this._vm.$hostname}/user`, { withCredentials: true })
        .then(({ data }) => {
          if (data.username) context.commit("fetchUser", data);
          else if (data.error === "Not LoggedIn!")
            context.commit("fetchUser", false);
          else if (data.error === "Too Many Request IP!") throw data.error;
          else throw "Something went wrong!";

          context.state.isLoading = false;
        })
        .catch(() => { });
    },

    async editUser(context, { payload }) {
      let ret = true;
      context.state.error = false;

      await axios.post(`${this._vm.$hostname}/user`, payload, { withCredentials: true })
        .then(({ data }) => {
          if (data.info) return
          else if (data.error === "Not LoggedIn!") router.go();
          else if (data.error === 'Could Not Save!')
            throw "We couldn't save your edit. The new username is already taken.";
          else throw "You've changed the user details too many times.";
        }).catch((err) => {
          ret = false
          context.state.event = true;
          context.state.messageEvent = err;
        });

      return ret;
    },

    async logOut(context) {
      context.state.isLoading = true;
      context.state.error = false;

      await axios.post(`${this._vm.$hostname}/logout`, {}, { withCredentials: true })
        .then(({ data }) => {
          if (data.info === "LoggedOut!") router.go();
          else throw "Something went wrong!";
        }).catch((err) => {
          context.state.event = true;
          context.state.messageEvent = err;
        });

      context.state.isLoading = false;
    },

    async deleteUser(context) {
      context.state.isLoading = true;
      context.state.error = false;

      await axios.post(`${this._vm.$hostname}/delete`, {}, { withCredentials: true })
        .then(({ data }) => {
          if (data.info === "Deleted Account!") router.go();
          else throw "Something went wrong!";
        })
        .catch((err) => {
          context.state.event = true;
          context.state.messageEvent = err;
        });

      context.state.isLoading = false;
    },

    async makePayment(context, { path }) {
      let orderCode = undefined;
      for (let i = 0; i < 2; i++)
        await axios.get(`${this._vm.$hostname}/${path}`, { withCredentials: true })
          .then(({ data }) => {
            if (data.error === "Not LoggedIn!") {
              context.commit("auth/changeComponent", { component: 'LogIn' });
              i = 2;
            }
            orderCode = data.orderCode
            if (orderCode) i = 2;
          })
          .catch(() => { });

      return orderCode;
    },

    async getTransaction(context, { t, eventId }) {
      context.state.messageEvent = eventId;

      if (t) await axios.get(`${this._vm.$hostname}/transaction/${t}`)
        .then(({ data }) => {
          if (data.id) router.push({ name: 'games', params: { id: data.id } })
          else throw "Something went wrong!"
        }).catch(() => { })
      else router.push({ name: 'main' })

      setTimeout(() => {
        context.state.event = true;
      }, 1000);
    },

    // async sendAreeas(context) {
    //   context.commit('SAVE_AREEA', { newAreea: '' });
    //   const info = this._vm.$cookies.get("areea");
    //   axios.post(`${this._vm.$hostname}/areasession`, { info }, { withCredentials: true });
    //   this._vm.$cookies.remove("areea");
    // },

    // async checkAreeasMobile(context) {
    // if (!window.onbeforeunload) {      
    //   const timestamp = new Date().getTime();
    //   let areea = this._vm.$cookies.get("arrea");
    //   if (areea) {
    //     areea = areea.split(',')[1];
    //     if ((timestamp - areea) / 1000 > 360)
    //       context.dispatch('sendAreeas', {});
    //   }
    // }
    // }
  },
  getters: {
    isLoading: (state) => state.isLoading,
    error: (state) => state.error,
    user: (state) => state.user,
    messageEvent: (state) => state.messageEvent,
    startAreea: (state) => state.startAreea,
    currentPage: (state) => state.currentPage,
    feedbackOpen: (state) => state.feedbackOpen,
    route: (state) => state.route,
    previousRoute: (state) => state.previousRoute,
    currentAreea: (state) => state.currentAreea
  },
});
