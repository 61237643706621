<template>
  <v-card color="#1d1f20" class="rounded-xl">
    <v-form
      v-model="valid"
      onSubmit="return false;"
      class="d-flex align-center flex-column"
      :class="[`pa-${Math.ceil(displaySize(10, 0.1))}`]"
    >
      <p
        :style="{ fontSize: `${displaySize(0.9, 0.08)}em` }"
        class="regularText mx-6 text-center"
      >
        We will send you a link on email to reset your password.
      </p>
      <v-text-field
        class="text-body-2 mt-3"
        color="white"
        v-model="email"
        :rules="$store.getters['auth/emailRules']"
        :style="{
          borderRadius: '10px',
          width: `${displaySize(250, 0.05)}px`,
        }"
        label="E-mail"
        outlined
        autofocus
        dense
        dark
        required
      ></v-text-field>
      <p
        v-if="$store.getters['auth/error']"
        hai
        class="red--text font-weight-bold text-caption"
      >
        {{ $store.getters["auth/messageError"] }}
      </p>
      <v-btn
        :dark="!valid || !email || $store.getters['auth/isLoading']"
        :disabled="!valid || !email || $store.getters['auth/isLoading']"
        color="#ffffff"
        class="btnText text-capitalize rounded-lg"
        large
        @click="forgotPassword"
        :width="`${displaySize(120, 0.05)}px`"
      >
        Send Email
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    email: "",
    valid: false,
  }),
  methods: {
    forgotPassword() {
      this.$store.dispatch("auth/forgotPassword", { email: this.email });
    },
  },
};
</script>

<style scoped>
</style>
