import axios from "axios";
import router from "@/router";
import store from "@/store";

export default {
  namespaced: true,
  state() {
    return {
      games: [],
      currentGame: {},
      openEntry: false,
      isLoading: true,
    };
  },
  mutations: {
    fetchGames(state, { games }) {
      if (games)
        state.games = games.sort((a, b) => {
          if (a.status === b.status)
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          return a.status - b.status;
        });
      // state.games = games
    },
  },
  actions: {
    fetchGames(context) {
      context.state.isLoading = true;
      context.rootState.event = false;
      axios
        .get(`${this._vm.$hostname}/games`, { withCredentials: true })
        .then(({ data }) => {
          if (data.games) context.commit("fetchGames", data);
          else if (data.error === "Too Many Request IP!") throw "You've made too many requests.";
          else throw "Something went wrong!";

          context.state.isLoading = false;
        })
        .catch((err) => {
          context.rootState.messageEvent = err;
          context.rootState.event = true;
        });
    },

    fetchGame(context, { id, navigateTo }) {
      context.rootState.event = false;

      axios
        .get(`${this._vm.$hostname}/games/${id}`, { withCredentials: true })
        .then(({ data }) => {
          if (data.game) {
            store.commit("game/fetchGame", data);
            if (navigateTo === 1)
              router.push({ name: "games", params: { id } })
          }
          else if (data.error === "Not LoggedIn!")
            store.commit("auth/changeComponent", { component: 'LogIn', props: { name: "games", params: { id } } });
          else if (data.error === "No Paid Entry!") {
            if (context.rootState.route.name === 'main') context.state.openEntry = true;
            else router.push({ name: 'main' })
          }
          else if (data.error === "Too Many Request IP!") throw "You've made too many requests.";
          else throw "Something went wrong!";
        })
        .catch((err) => {
          context.rootState.messageEvent = err;
          context.rootState.event = true;
        });
    },
  },
  getters: {
    games: (state) => state.games,
    currentGame: (state) => state.currentGame,
    openEntry: (state) => state.openEntry,
    isLoading: (state) => state.isLoading,
  },
};
