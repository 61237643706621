<template>
  <v-card
    min-width="160px"
    min-height="320px"
    height="75vh"
    class="d-flex align-center flex-column"
    color="#1d1f20"
  >
    <the-antet :who="'log'"></the-antet>
    <v-form
      v-model="valid"
      style="width: 100%"
      class="d-flex align-center flex-column pt-7"
    >
      <v-text-field
        class="text-body-2"
        v-model="username"
        label="E-mail or username"
        :rules="$store.getters['auth/usernameRules']"
        outlined
        dense
        dark
        autofocus
        :style="{ borderRadius: '10px', width: `${displaySize(55, -0.2)}%` }"
        required
        :counter="25"
        color="white"
      ></v-text-field>
      <v-text-field
        class="text-body-2"
        v-model="password"
        label="Password"
        :type="passType"
        :append-icon="passType === 'text' ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="togglePassType"
        outlined
        dense
        dark
        :style="{ borderRadius: '10px', width: `${displaySize(55, -0.2)}%` }"
        color="white"
        :rules="$store.getters['auth/passwordRules']"
        :counter="20"
        required
        @keyup.enter="notValidForm || logIn()"
      ></v-text-field>
      <p
        :style="{ opacity: `${$store.getters['auth/error'] ? 1 : 0}` }"
        class="red--text font-weight-bold text-caption mt-0 mb-1"
      >
        {{ $store.getters["auth/messageError"] }}&nbsp;
      </p>
      <loading-circle
        :position="'relative'"
        :size="40"
        v-if="$store.getters['auth/isLoading']"
      ></loading-circle>
      <v-btn
        v-else
        :dark="notValidForm"
        :disabled="notValidForm"
        color="#ffffff"
        class="btnText text-capitalize rounded-lg"
        large
        @click="logIn"
        :width="`${displaySize(120, 0.05)}px`"
      >
        Login
      </v-btn>

      <a class="my-4 forgotPass" @click="changeComponent('ForgotPassword')">
        Forgot password?
      </a>
    </v-form>
  </v-card>
</template>

<script>
import TheAntet from "./TheAntet.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: { TheAntet, LoadingCircle },
  data: () => ({
    valid: false,
    username: "",
    password: "",
    passType: "password",
  }),
  computed: {
    notValidForm() {
      return !this.valid || !this.username || !this.password;
    },
  },
  methods: {
    togglePassType() {
      this.passType = this.passType === "text" ? "password" : "text";
    },
    changeComponent(component) {
      this.$store.state.auth.dialog = false;
      // we need to wait 400ms because se formateaza prost din se trece de la login la forgotPass
      setTimeout(() => {
        this.$store.commit("auth/changeComponent", { component });
      }, 350);
    },
    logIn() {
      const password = this.password;
      const username = this.username;
      this.$store.dispatch("auth/logIn", { password, username });
    },
  },
};
</script>

<style scoped>
.forgotPass {
  color: #00a9de;
  font-weight: 300;
  font-size: 80%;
}
.forgotPass:hover {
  color: #03bff9;
  transform: scale(1.01);
  text-decoration: underline;
}

.v-text-field--outlined >>> label {
  font-size: 13px;
}

.v-text-field--outlined >>> button {
  font-size: 18px;
}
</style>
