import Vue from "vue";
import App from "./App.vue";
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = true;
// Vue.config.ignoredElements = [/^ion-/]

Vue.prototype.$country = 'Your country';
Vue.prototype.$hostname = (Vue.config.productionTip) ? 'https://backend.masterycase.com' : 'http://192.168.1.12:3000'
Vue.prototype.$vivaCheckout = (Vue.config.productionTip) ?
  'https://www.vivapayments.com/web/checkout?ref=' : 'https://demo.vivapayments.com/web/checkout?ref='
Vue.prototype.$getDate = (date) => {
  date = new Date(date).getTime();
  const month = new Date(date).toLocaleString("default", {
    month: "short",
  });
  const day = new Date(date).getDate();
  const year = new Date(date).getFullYear();
  const hour = new Date(date).toLocaleTimeString();
  return `${day} ${month} ${year}, ${hour}`;
}
Vue.mixin({
  data: () => ({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  }),
  computed: {
    isMobile() {
      return this.windowWidth < 700
      // return this.$vuetify.breakpoint.mobile;
    },
    isAndroid() {
      if (/android/i.test(navigator.userAgent))
        return true;
      return false;
    },
    isIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    isSafariMAC() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('mac os') != -1 && ua.indexOf('safari') != -1 && ua.indexOf('chrome') <= -1)
        return true;
      return false
    }
  },
  methods: {
    displaySize(defaultSize, step) {
      if (!step) step = 0.2

      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return defaultSize * (1 - 3 * step)
        case 'sm': return defaultSize * (1 - 2 * step)
        case 'md': return defaultSize * (1 - step)
        case 'lg': return defaultSize
        case 'xl': return defaultSize * (1 + step)
      }
    },
    getHeight(value, procent) {
      return procent ? window.innerHeight * value : window.innerHeight - value;
    },
    goExternApp(link) {
      if (this.isIOS) window.location = link
      else window.open(link, "_blank");
    },
    getDate(date) {
      return this.$getDate(date)
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    // if (!this.isIOS && !this.isAndroid)
    //   window.onbeforeunload = function () {
    //     store.dispatch('sendAreeas');
    //   };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
})

Vue.use(VueMeta, {
  refresh() {
    const tags = VueMeta.generate(meta => ({
      ...meta,
      vmid: null
    }))
    this.setMetaTags(tags)
  }
});
Vue.use(VueCookies);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
