module.exports.eventsIdViva = {
    0: "The payment was succesfull.",
    2061: "Browser closed before authentication finished.",
    2062: "Wrong password or two-factor auth code entered.",
    2108: "Payments Policy Acquiring Restriction.",
    10001: "The issuing bank prevented the transaction.",
    10003: "Security violation (source is not correct issuer).",
    10004: "The card has been designated as lost or stolen.",
    10005: "The issuing bank declined the transaction without an explanation.",
    10006: "The card issuer has declined the transaction as there is a problem with the card number.",
    10012: "The bank has declined the transaction because of an invalid format or field. This indicates the card details were incorrect.",
    10013: "The card issuer has declined the transaction because of an invalid format or field.",
    10014: "The card issuer has declined the transaction as the credit card number is incorrectly entered or does not exist.",
    10015: "The card issuer doesn't exist.",
    10030: "The card issuer does not recognise the transaction details being entered. This is due to a format error.",
    10041: "The card issuer has declined the transaction as the card has been reported lost.",
    10043: "The card has been designated as lost or stolen.",
    10051: "The card has insufficient funds to cover the cost of the transaction.",
    10054: "The payment gateway declined the transaction because the expiration date is expired or does not match.",
    10057: "The card issuer has declined the transaction as the credit card cannot be used for this type of transaction.",
    10058: "The card issuer has declined the transaction as the credit card cannot be used for this type of transaction.",
    10061: "Exceeds withdrawal amount limit.",
    10062: "The customer's bank has declined their card.",
    10063: "Flag raised due to security validation problem.",
    10065: "The issuer requests Strong Customer Authentication. The merchant should retry the transaction after successfully authenticating customer with 3DS first.",
    10070: "Contact card issuer.",
    10075: "Allowable number of PIN tries exceeded.",
    10076: "Invalid / non-existent OR Invalid / non-existent specified.",
    10096: "A temporary error occurred during the transaction.",
    10200: "A temporary error occurred during the transaction.",
    10301: "The issuer requests Strong Customer Authentication. The merchant should retry the transaction after successfully authenticating customer with 3DS first."
}