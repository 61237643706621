import axios from "axios";
import sha256 from "js-sha256";
import store from "@/store";
import router from "../../router";

export default {
  namespaced: true,
  state() {
    return {
      game: {},
      error: false,
      messageError: "",
      isLoading: false,
      openHints: false,
      openIncreasePrize: false,
      openAnswer: false,
      openWin: false,
      openEntry: false,
      amountPay: 0,
    };
  },
  mutations: {
    fetchGame(state, { game, amountPay }) {
      state.game = game;
      state.amountPay = amountPay
    },
  },
  actions: {
    async fetchAnswer(context, { id }) {
      let solution = {};

      await axios
        .get(`${this._vm.$hostname}/games/${id}/solution`, { withCredentials: true, })
        .then(({ data }) => {
          if (data.solution) solution = data.solution;
          else if (data.error === "Too Many Request IP!") throw data.error;
          else if (data.error === "Not LoggedIn!") {
            store.commit("auth/changeComponent", { component: 'LogIn' });
            return
          }
          else if (data.error === "No Paid Solution!")
            solution = data.error;
          else throw "Something went wrong!";
        })
        .catch(() => { solution = {} });

      return solution;
    },

    async fetchHints(context, { id }) {
      let hints = [];

      await axios
        .get(`${this._vm.$hostname}/games/${id}/hints`, { withCredentials: true })
        .then(({ data }) => {
          if (data.hints) hints = data.hints;
          else if (data.error === "Too Many Request IP!") throw data.error;
          else if (data.error === "Not LoggedIn!") {
            store.commit("auth/changeComponent", { component: 'LogIn' });
            return;
          }
          else if (data.error === "No Paid Hints!") hints = data.error;
          else throw "Something went wrong!";

          context.state.openHints = true;
        })
        .catch(() => { hints = [] });

      return hints;
    },

    postPassword(context, { password }) {
      context.state.error = false;

      const game = context.getters["game"];
      const path = `${this._vm.$hostname}/games/${game._id}/password`

      if (!game.status) {
        axios.post(path, { password, status: 0 }, { withCredentials: true })
          .catch(() => { })
        if (sha256(password) === game.password) context.state.openWin = true;
        else {
          context.state.messageError = "The secret code is invalid. Try again.";
          context.state.error = true;
        }
      }
      else {
        axios.post(path, { password, status: 1 }, { withCredentials: true })
          .then(({ data }) => {
            if (data.info === "Good password!") context.state.openWin = true;
            else if (data.info === "Game finished!") router.go();
            else if (data.info === "Bad password!") throw "The secret code is invalid. Try again.";
            else if (data.error === "Not LoggedIn!")
              context.commit("auth/changeComponent", { component: "LogIn" }, { root: true });
            else if (data.error === "Too Many Request PASS!") throw "You tried so many times today";
            else if (data.error === "No Paid Prize!") context.state.openIncreasePrize = true;
            else throw "Something went wrong!";
          })
          .catch((err) => {
            context.state.messageError = err;
            context.state.error = true;
          });
      }
    },
  },
  getters: {
    game: (state) => state.game,
    isLoading: (state) => state.isLoading,
    error: (state) => state.error,
    messageError: (state) => state.messageError,
    openHints: (state) => state.openHints,
    openIncreasePrize: (state) => state.openIncreasePrize,
    amountPay: (state) => state.amountPay,
    openAnswer: (state) => state.openAnswer,
    openWin: (state) => state.openWin,
    openEntry: (state) => state.openEntry,
  },
};
