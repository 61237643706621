<template>
  <div id="wrapper">
    <v-progress-circular
      :rotate="-90"
      :size="size || 50"
      :width="width || 5"
      :value="value"
      :color="color || 'green'"
      class="v-progress-circular"
      :style="{ position: position, fontSize: '80%' }"
    >
      {{ noValue ? "" : value }}
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  props: ["size", "width", "color", "noValue", "position"],
  data() {
    return {
      interval: {},
      value: 0,
    };
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 700);
  },
};
</script>

<style scoped>
.v-progress-circular {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
}
</style>
