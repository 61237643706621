<template>
  <div class="text d-flex align-center">
    <v-col cols="1"> </v-col>
    <v-col cols="10" class="ma-0 pa-0">
      <slot></slot>
    </v-col>
    <v-col cols="1" class="ma-0 pa-0">
      <v-tooltip
        v-if="message"
        max-width="300px"
        :right="isMobile ? false : true"
        :left="isMobile ? true : false"
        :transition="`slide-x-${isMobile ? '' : 'reverse'}-transition`"
        color="rgb(47,47,47, 1)"
        ><template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            dense
            color="rgb(200, 200, 200)"
            :class="['mb-6', `ml-${Math.ceil(displaySize(3))}`]"
            >mdi-information-variant</v-icon
          ></template
        >
        <p
          :style="{ fontSize: `${displaySize(1, 0.05)}em` }"
          :class="[!isMobile ? 'tooltipText' : '', 'ma-0']"
        >
          {{ message }}
        </p>
      </v-tooltip>
    </v-col>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style  scoped>
</style>