import Vue from "vue";
import Router from "vue-router";
import store from '@/store';
import { eventsIdViva } from './utils/eventIdViva';

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      name: "main",
      path: "/",
      component: () => import("./apps/main/pages/MainPage.vue"),
      beforeEnter: async (to, from, next) => {
        if (from.name === null && to.query.s && to.query.eventId) {
          let eventId = eventsIdViva[to.query.eventId];
          if (to.query.eventId !== '0') eventId = "The payment has failed. " + eventId
          store.dispatch('getTransaction', { t: to.query.t, eventId });
        }
        else next()
      }
    },
    {
      name: "howToPlay",
      path: "/how-to-play",
      component: () => import("./pages/HowToPlayPage.vue"),
      meta: { transMode: '' },
    },
    {
      name: "games",
      path: "/games/:id",
      component: () => import("./apps/game/pages/GamePage.vue"),
      props: true,
    },
    {
      name: "solution",
      path: "/games/:id/solution",
      component: () => import("./apps/game/pages/SolutionPage.vue"),
      props: true,
    },
    {
      name: "resetPassword",
      path: "/resetPassword",
      component: () => import("./apps/auth/pages/ResetPassword.vue"),
    },
    {
      name: "news",
      path: "/news",
      component: () => import("./apps/news/pages/NewsPage.vue"),
    },
    {
      name: "about",
      path: "/about/:type",
      component: () => import("./pages/AboutPage.vue"),
      props: true,
    },
    // {
    //   name: "try",
    //   path: "/try",
    //   component: () => import("./components/Try.vue"),
    // },
    {
      path: "*",
      redirect: { name: 'main' }
    }
  ],
});

router.beforeEach((to, from, next) => {
  store.commit('SET_ROUTE', { to, from });
  //store.commit('SAVE_AREEA', { newAreea: to.path });
  // store.dispatch('checkAreeasMobile');
  next();
});

export default router;