import axios from "axios";
import router from "@/router";

export default {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      dialog: false,
      component: "",
      error: false,
      messageError: "",
      usernameRules: [
        (v) => v.length <= 25 || "This must be less than 25 characters",
        (v) => v.length === 0 || v.length >= 3 || "This must be more than 3 caracters",
      ],
      passwordRules: [
        (v) => v.length <= 20 || "This must be less than 20 characters",
        (v) => v.length === 0 || v.length >= 9 || "This must be more than 9 caracters",
      ],
      emailRules: [
        (v) => v.length === 0 || /.+@.+/.test(v) || "E-mail must be valid",
      ],
      props: {},
    };
  },
  mutations: {
    changeComponent(state, { component, props }) {
      state.props = props || {};
      state.error = false;
      state.dialog = true;
      state.component = component;
    },
  },
  actions: {
    async signUp(context, payload) {
      context.state.isLoading = true;
      context.state.error = false;

      await axios.post(`${this._vm.$hostname}/register`, payload, { withCredentials: true, })
        .then(({ data }) => {
          if (data.info === "Code Sent!") {
            context.state.dialog = false
            setTimeout(() => { context.commit("changeComponent", { component: "OTPCode", props: payload, }) }, 350)
          }
          else if (data.error === "Too Many Request LOG!") throw "You've tried to login too many times.";
          else if (data.error === "Email Taken!") throw data.error;
          else if (data.error === "Username Taken!") throw data.error;
          else if (data.error === "LoggedIn!") router.go();
          else if (data.error === "Exceed resendAttemps!") throw "You've tried too many times with this mail.";
          else if (data.error === "Invalid Email!") throw data.error;
          else throw "Something went wrong!";
        })
        .catch((err) => {
          context.state.error = true;
          context.state.messageError = err;
        });

      context.state.isLoading = false;
    },

    async sendCode(context, payload) {
      context.state.isLoading = true;
      context.state.error = false;

      await axios
        .post(`${this._vm.$hostname}/verify`, payload, { withCredentials: true, })
        .then(({ data }) => {
          if (data.info === "Registered!") context.dispatch("logIn", payload);
          else if (data.error === "LoggedIn!") router.go();
          else if (data.error === "Bad Code!") throw "Invalid Code!";
          else if (data.error === "Exceed codeAttemps!") throw "You've tried too many times to resend the code.";
          else throw "Something went wrong!";
        })
        .catch((err) => {
          context.state.error = true;
          context.state.messageError = err;
        });

      context.state.isLoading = false;
    },
    logIn(context, { username, password }) {
      context.state.isLoading = true;
      context.state.error = false;

      axios.post(`${this._vm.$hostname}/login`, { username, password }, { withCredentials: true })
        .then(({ data }) => {
          if (data.info === "LoggedIn!" || data.error === "LoggedIn!") router.go()
          else if (data.error === "Too Many Request LOG!") throw "You've tried to login too many times.";
          else if (data.error === "Bad credentials to login!") throw data.error;
          else throw "Something went wrong!";
        })
        .catch((err) => {
          context.state.isLoading = false;
          context.state.error = true;
          context.state.messageError = err;
        });
    },
    async forgotPassword(context, payload) {
      context.state.isLoading = true;
      context.state.error = false;

      await axios.post(`${this._vm.$hostname}/forgotPass`, payload, { withCredentials: true, })
        .then(({ data }) => {
          if (data.info === "Code Sent!") {
            context.state.dialog = false;
            context.rootState.messageEvent = "The link has been sent! Check your inbox and spam folder.";
            context.rootState.event = true;
          } else if (data.error === "Too Many Request LOG!") throw "You've tried to login too many times.";
          else if (data.error === "Email not registered!") throw data.error;
          else throw "Something went wrong!";
        })
        .catch((err) => {
          context.state.error = true;
          context.state.messageError = err;
        });

      context.state.isLoading = false;
    },
    async resetPassword(context, payload) {
      context.state.isLoading = true;
      context.state.error = false;

      await axios.put(`${this._vm.$hostname}/forgotPass`, payload, { withCredentials: true, })
        .then(({ data }) => {
          if (data.info === "Password changed!") {
            context.rootState.messageEvent = "The password has changed!";
            context.rootState.event = true;
            setTimeout(() => { router.push({ name: "main" }); }, 3000);
          } else if (data.error === "Too Many Request LOG!") throw "You've tried to login too many times.";
          else if (data.error === "Invalid email or code!") throw data.error;
          else if (data.error === "ExpDate!") throw "The code expired.";
          else throw "Something went wrong!";
        })
        .catch((err) => {
          context.state.error = true;
          context.state.messageError = err;
        });
      context.state.isLoading = false;
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    error: (state) => state.error,
    dialog: (state) => state.dialog,
    component: (state) => state.component,
    usernameRules: (state) => state.usernameRules,
    passwordRules: (state) => state.passwordRules,
    emailRules: (state) => state.emailRules,
    messageError: (state) => state.messageError,
    props: (state) => state.props,
  },
};
