<template>
  <!-- <ion-app> -->
  <v-app id="vapp">
    <v-main>
      <transition :name="$route.name" :mode="$route.meta.transMode || 'out-in'">
        <router-view></router-view>
      </transition>
      <v-dialog
        content-class="round-dialog"
        overlay-opacity="0.7"
        :max-width="dialogMaxWidth()"
        v-model="$store.state.auth.dialog"
        :persistent="$store.state.auth.component === 'OTPCode'"
      >
        <keep-alive>
          <component :is="$store.state.auth.component"></component>
        </keep-alive>
      </v-dialog>
      <v-dialog
        content-class="round-dialog"
        overlay-opacity="0.7"
        max-width="400px"
        v-model="$store.state.event"
        persistent
      >
        <v-card
          min-height="150px"
          class="d-flex flex-column align-center py-5"
          :class="[isMobile ? 'px-2' : 'px-5']"
          color="#1d1f20"
        >
          <p
            class="regularText text-center font-weight-medium mb-9"
            style="font-size: 1em"
          >
            {{ $store.state.messageEvent }}
          </p>
          <v-btn
            class="btnText rounded-lg"
            style="font-size: 90%; font-weight: 900; text-transform: none"
            width="25%"
            small
            @click="$store.state.event = false"
            >Ok
          </v-btn>
        </v-card>
      </v-dialog>
      <v-img
        id="slideout"
        v-show="['main', 'game'].includes($store.state.currentPage)"
        :class="[isMobile ? 'shadowCard' : 'primPlan']"
        :style="{ transform: `scale(1)` }"
        @click="$router.push({ name: 'howToPlay' })"
        :src="require('@/assets/HowToPlay.svg')"
        :max-width="`${displaySize(30, 0.05)}px`"
      />
    </v-main>
    <div
      v-if="showCookie && $store.getters['currentPage'] !== 'about'"
      style="
        opacity: 0.55;
        background-color: black;
        height: 100%;
        width: 100%;
        position: fixed;
        pointer-events: none;
        z-index: 30;
      "
    ></div>
    <v-footer
      style="z-index: 1000"
      v-if="showCookie && $store.getters['currentPage'] !== 'about'"
      fixed
      :padless="true"
    >
      <v-card
        flat
        tile
        width="100%"
        color="#2B2B2C"
        class="d-flex align-center py-2 px-4"
        :class="[isMobile ? 'flex-column' : '']"
      >
        <v-card-text
          class="regularText white--text"
          :class="[isMobile ? '' : 'mr-16']"
          :style="{ fontSize: `${displaySize(0.9, 0.05)}em` }"
        >
          Notice. We use cookies to provide website functionalities and
          services, improve your experience and analyze our traffic. By using
          our website, you agree to our
          <a
            style="color: #00a9de"
            target="_blank"
            href="/about/Privacy%20Policy"
          >
            Privacy Policy
          </a>
          and our
          <a
            style="color: #00a9de"
            target="_blank"
            href="/about/Cookies%20Policy"
            >Cookies Policy</a
          >.
        </v-card-text>

        <v-btn
          style="
            border: 2px solid #00aeff;
            background-color: #0e87cc;
            color: white;
          "
          :style="isMobile || { marginRight: '6%' }"
          width="150px"
          @click="acceptCookie"
          >Ok</v-btn
        >
      </v-card>
    </v-footer>
  </v-app>
  <!-- </ion-app> -->
</template>

<script>
import ForgotPassword from "../src/apps/auth/components/ForgotPassword.vue";
import LogIn from "../src/apps/auth/components/LogIn.vue";
import SignUp from "../src/apps/auth/components/SignUp.vue";
import OTPCode from "../src/apps/auth/components/OTPCode.vue";
import TheAccount from "../src/apps/auth/components/TheAccount.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    ForgotPassword,
    LogIn,
    SignUp,
    OTPCode,
    TheAccount,
  },
  data: () => {
    return { showCookie: false };
  },
  computed: {},
  metaInfo() {
    return {
      title: this.metaTitle(),
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no",
        },
        { name: "description", content: this.metaDescription() },
        this.metaFacebook(),
        //Capacitor
        // {
        //   name: "format-detection",
        //   content: "telephone=no",
        // },
        // {
        //   name: "msapplication-tap-highlight",
        //   content: "no",
        // },
      ],
    };
  },
  methods: {
    metaTitle() {
      const currPage = this.$store.getters["currentPage"];

      if (currPage === "game")
        return this.$store.state.game.game.title + " | Mastery Case";
      else if (currPage === "solution")
        return (
          "Solution for" + this.$store.state.game.game.title + " | Mastery Case"
        );
      else if (currPage === "news") return "News | Mastery Case";
      else if (currPage === "howToPlay") return "How To Play | Mastery Case";
      else if (this.$store.getters["route"].name === "resetPassword")
        return "Reset Password | Mastery Case";
      else if (this.$store.getters["route"].name === "about")
        return this.$store.getters["route"].params.type + " | Mastery Case";
      return "Mastery Case";
    },
    metaDescription() {
      const currPage = this.$store.getters["currentPage"];
      if (currPage === "news")
        return "Here we announce the launch date of puzzles, the winners, the prizes and more.";
      else if (currPage === "howToPlay")
        return "Check out a short set of rules to better understand the game.";
      else if (this.$store.getters["route"].name === "about") return "";
      return "Engage in the most outstanding and revolutionary mystery puzzle cases, where you can win real money by discovering the Secret Code. The game is full of secrets, hidden messages, riddles, enigmas, and mysteries, ready to be revealed by a skilled detective, with outside the box thinking.";
    },
    metaFacebook() {
      if (this.$store.getters["route"].name === "main")
        return {
          name: "facebook-domain-verification",
          content: "wrtw5eubhocrqbkzgywcljcikf873l",
        };
      return {};
    },
    dialogMaxWidth() {
      const list400 = ["OTPCode", "ForgotPassword"];
      if (list400.includes(this.$store.state.auth.component)) return 400;
      else if (this.$store.state.auth.props.deleteUser) return 400;
      return 550;
    },
    acceptCookie() {
      localStorage.setItem("cookieAccept", true);
      axios.post(
        `${this.$hostname}/updateSession`,
        { cookieConsent: true },
        { withCredentials: true }
      );
      this.showCookie = false;
    },
    getUpdateGA_Id() {
      if (this.$cookies.get("sentGA_Id") === null)
        setTimeout(() => {
          if (this.$cookies.get("_ga") === null) this.getUpdateGA_Id();
          else {
            axios.post(
              `${this.$hostname}/updateSession`,
              { GA_Id: this.$cookies.get("_ga") },
              { withCredentials: true }
            );
            this.$cookies.set("sentGA_Id", true);
          }
        }, 5000);
    },
  },
  created() {
    if (!localStorage.getItem("cookieAccept")) this.showCookie = true;
    this.getUpdateGA_Id();
  },
};
</script>

<style >
@import url("https://fonts.googleapis.com/css?family=Poppins:600italic,400,700,500,600");
/* @import url("//db.onlinewebfonts.com/c/698a87c0f647c638165c17232cfcf5b8?family=Subway+Novella"); */
/* @import url("//db.onlinewebfonts.com/c/08a4f684fb0599188430dd0b97af52ac?family=Bookman+Old+Style"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

.main-leave-active,
.games-leave-active,
.news-leave-active {
  transition: opacity 0.2s;
}
.main-leave-to,
.games-leave-to,
.news-leave-to {
  opacity: 0;
}
.main-enter-active,
.games-enter-active,
.news-enter-active {
  transition: opacity 1s;
}
.main-enter,
.games-enter,
.news-enter {
  opacity: 0;
}

.howToPlay-enter-active {
  transition: opacity 0.4s, transform 0.4s;
}
.howToPlay-enter {
  opacity: 0;
  transform: translateX(100%);
}
.howToPlay-leave-active {
  transition: opacity 0.1s;
}
.howToPlay-leave-to {
  opacity: 0;
}
.howToPlay-leave-from {
  transform: translateX(-100%);
}

#vapp {
  background: url("./assets/BackgroundApp.webp") no-repeat center fixed;
  background-size: 100% 100%;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.breakline {
  width: 80%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}
.vertical-breakline {
  width: 2px;
  border: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}

.centerDiv {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.mTitleGame {
  font-family: "Subway Novella", serif;
  font-weight: 400;
  letter-spacing: 0.3em;
  margin-right: -0.3em;
  background: linear-gradient(180deg, #d6d6d6 76.98%, #181616 13.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.primPlan,
.onClick {
  transition: all 0.1s ease-in-out;
}
.primPlan:hover,
.onClick:active {
  filter: drop-shadow(0 0 0.4rem #c8c8c8);
  transform: scale(1.1);
  cursor: pointer;
}
.safariPrimPlan {
  transition: all 0.1s ease-in-out;
}
.safariPrimPlan:hover {
  /* filter: drop-shadow(0 0 0.2rem #c8c8c8); */
  transform: scale(1.1);
  cursor: pointer;
}

.regularText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 550;
  font-size: 28px;
  letter-spacing: 1px;
  color: #ffffff;
}

.poppinsText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1em;
  background: linear-gradient(180deg, #e4e4e4 47.92%, #6d6d6d 73.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.robotoText {
  font-family: "Roboto Slab", serif;
  font-size: 22px;
  /* -webkit-text-stroke: 1px black; */
  text-shadow: 1.5px 0 0 black, -1.5px 0 0 black, 0 1.5px 0 black,
    0 -1.5px 0 black;
}

.btnText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: #000000;
}

.shadowCard {
  filter: drop-shadow(0 0 0.6rem black);
}

.v-input--checkbox .v-label {
  font-size: 80%;
}
/* minimize space between label and checkBox */
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 5px;
}
.footer {
  position: absolute;
  bottom: 0;
}

.tooltipText::after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  transform: rotate(90deg);
  border-width: 5px;
  border-style: solid;
  margin: -5px 0;
  border-color: hsl(0, 0%, 18%) transparent transparent transparent;
}

#slideout {
  z-index: 20;
  position: fixed;
  top: 40%;
  right: 0;
}

.scroll::-webkit-scrollbar-button {
  height: 30px;
}

.scroll::-webkit-scrollbar {
  width: 7px;
}

.scroll::-webkit-scrollbar-track {
  background: #2f2f2f;
  border-radius: 50px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #0f0f0f;
  border-radius: 50px;
  border: 1px solid #505050;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: black;
}

.noScroll::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1d1f20 inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.round-dialog {
  border-radius: 25px !important;
}

.v-top-sheet {
  align-self: flex-start !important;
  margin-top: 95px !important;
  transition: none !important;
  /* transition: 0s cubic-bezier(0.25, 0.8, 0.25, 1) !important; */
}
</style>
