<template>
  <v-card
    color="#1d1f20"
    class="d-flex align-center flex-column"
    :class="[`pa-${Math.ceil(displaySize(10, 0.15))}`]"
  >
    <p
      :style="{ fontSize: `${displaySize(14, 0.05)}px` }"
      class="regularText mx-6 text-center"
    >
      We've sent to
      <span style="font-size: 120%; display: block"
        >{{ this.$store.getters["auth/props"].email }} </span
      >a verification code. Enter it below. Check your inbox and spam folder.
    </p>
    <v-otp-input
      v-model="code"
      class=""
      type="number"
      plain
      style="max-width: 80%; font-weight: 800"
      length="6"
    ></v-otp-input>
    <p
      :style="{ opacity: $store.getters['auth/error'] ? 1 : 0 }"
      class="red--text font-weight-bold text-caption ma-0 pa-0"
    >
      {{ $store.getters["auth/messageError"] }}&nbsp;
    </p>
    <loading-circle
      :position="'relative'"
      :size="40"
      v-if="$store.getters['auth/isLoading']"
    ></loading-circle>
    <v-btn
      v-else
      color="#ffffff"
      class="btnText text-capitalize rounded-lg"
      large
      @click="sendCode"
      :width="`${displaySize(120, 0.02)}px`"
    >
      Enter Code
    </v-btn>
    <p v-if="resendCode" style="color: #00a9de" class="mt-3 ma-0 text-caption">
      Resend Code: {{ resendCode }} sec
    </p>
    <a @click="signUpAgain" v-else class="mt-3 ma-0 resend"> Resend Code </a>
  </v-card>
</template>

<script>
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: { LoadingCircle },
  data: () => ({
    code: "",
    resendCode: 0,
    interval: {},
  }),
  watch: {
    code() {
      if (this.$store.getters["auth/error"])
        this.$store.state.auth.error = false;
    },
  },
  methods: {
    setInterval() {
      this.resendCode = 60;
      this.interval = setInterval(() => {
        if (this.resendCode === 0) return clearInterval(this.interval);
        this.resendCode--;
      }, 1000);
    },
    sendCode() {
      const payload = this.$store.getters["auth/props"];
      this.$store.dispatch("auth/sendCode", { ...payload, code: this.code });
    },
    signUpAgain() {
      this.setInterval();
      this.$store.dispatch("auth/signUp", {
        ...this.$store.getters["auth/props"],
      });
    },
  },
  mounted() {
    this.setInterval();
  },
};
</script>

<style scoped>
.resend {
  color: #00a9de;
  font-weight: 300;
  font-size: 80%;
}
.resend:hover {
  color: #03bff9;
  transform: scale(1.01);
  text-decoration: underline;
}
</style>
