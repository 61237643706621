<template>
  <div :class="['d-flex', 'align-center', `mt-${Math.ceil(displaySize(6))}`]">
    <div
      :style="{ fontSize: `${displaySize(1.4, 0.1)}em`, cursor: 'pointer' }"
      style="letter-spacing: 1px"
      :class="['poppinsText', who === 'log' ? '' : 'textBlack']"
      @click="changeComponent('LogIn')"
    >
      &nbsp;&nbsp;&nbsp;Login
    </div>
    <hr class="vertical-breakline mx-4" />
    <div
      :style="{ fontSize: `${displaySize(1.4, 0.1)}em`, cursor: 'pointer' }"
      style="letter-spacing: 1px"
      :class="['poppinsText', who === 'sig' ? '' : 'textBlack']"
      @click="changeComponent('SignUp')"
    >
      Sign Up
    </div>
    <v-btn
      v-if="isMobile"
      class="align-self-end"
      text
      fab
      absolute
      dark
      style="top: 2%; right: 2%"
      x-small
      @click="$store.state.auth.dialog = false"
      ><v-icon>mdi-close</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  props: ["who"],
  data: () => ({}),
  methods: {
    changeComponent(component) {
      this.$store.commit("auth/changeComponent", { component });
    },
  },
};
</script>

<style scoped>
.textBlack {
  background: linear-gradient(
    180deg,
    rgba(228, 228, 228, 0.45) 47.92%,
    rgba(109, 109, 109, 0.45) 73.44%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
